var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[(_vm.buildErrorAnalysisData && !_vm.isLoading)?_c('b-card',{attrs:{"title":"Build Error Analysis Edit"}},[_c('validation-observer',{ref:"appForm",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();_vm.validationFormControl().then(_vm.formSubmitted)}}},[_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","maxlength":"3","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Build Error Name"},model:{value:(_vm.buildErrorAnalysisData.name),callback:function ($$v) {_vm.$set(_vm.buildErrorAnalysisData, "name", $$v)},expression:"buildErrorAnalysisData.name"}})]}}],null,false,1295455566)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Regex","label-for":"code"}},[_c('validation-provider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"name":"regex","placeholder":"Regex","rows":"3"},model:{value:(_vm.buildErrorAnalysisData.regex),callback:function ($$v) {_vm.$set(_vm.buildErrorAnalysisData, "regex", $$v)},expression:"buildErrorAnalysisData.regex"}})]}}],null,false,1792755228)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description","label-for":"buildErrorDescription","state":errors.length > 0 ? false:null}},[_c('quill-editor',{attrs:{"options":_vm.editorOption,"id":"quilBrief"},model:{value:(_vm.buildErrorAnalysisData.description),callback:function ($$v) {_vm.$set(_vm.buildErrorAnalysisData, "description", $$v)},expression:"buildErrorAnalysisData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1560777446)})],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Solution","label-for":"buildErrorSolution","state":errors.length > 0 ? false:null}},[_c('quill-editor',{attrs:{"options":_vm.editorOption,"id":"quilBrief"},model:{value:(_vm.buildErrorAnalysisData.solution),callback:function ($$v) {_vm.$set(_vm.buildErrorAnalysisData, "solution", $$v)},expression:"buildErrorAnalysisData.solution"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1392011801)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1):_vm._e(),(_vm.isLoading)?_c('b-card',{staticClass:"mt-2"},[_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"d-block mx-auto mb-50"}),_c('strong',[_vm._v("Content is being created, please wait...")])],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }